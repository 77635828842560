import { Link } from "gatsby"
import React from "react"

export default function Footer({facebook, twitter, instagram}) {
  return (
    <footer className="footer">
        <div className="footer__left">
        <ul className="footer__info">
            <li>© 2021 created by <a href="https://www.richardbutler.dev" target="_blank">richardbutler.dev</a></li>
        </ul>
      </div>
      <ul className="footer__social">
        <li><Link to="/terms/">Terms & Conditions</Link></li>
        <li><a href={facebook} className="social-link" target="_blank" rel="noreferrer"><i className="icon-facebook-alt"></i></a></li>
        <li><a href={twitter} className="social-link" target="_blank" rel="noreferrer"><i className="icon-twitter-alt"></i></a></li>
        <li><a href={instagram} className="social-link" target="_blank" rel="noreferrer"><i className="icon-instagram"></i></a></li>
      </ul>
    </footer>
  )
}