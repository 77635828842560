import React, {useState} from "react"
import Logo from "../images/logoicon.svg"

export default function Header({headerLogo, bookingLink}) {
  
  const [hamburger, setHamburger] = useState(false)


  return (
    <header className="header d-lg-block">
	    <nav className="navbar navbar-overlay d-none d-md-flex">
            <a className="logo-link" href="/">
                <img className="logotype" src={headerLogo.asset.fluid.src} alt="Atlantic Lookout"/>
		    </a>
				<div className="navbar__menu">
		        <button className={`hamburger ${hamburger && "is-active"}`} type="button" onClick={() => setHamburger(!hamburger)}>
				    <span></span>
					<span></span>
				</button>
			    <ul className={`nav ${hamburger && "is-open"}`}>
				    <li className="nav__item _is-current"><a className="nav__link" href="#about"><span data-hover="About">About</span></a></li>
				    <li className="nav__item"><a className="nav__link" href="#gallery"><span data-hover="Gallery">Gallery</span></a></li>
				    <li className="nav__item"><a className="nav__link" href="#details"><span data-hover="Details">Details</span></a></li>
				    <li className="nav__item"><a className="nav__link" href="#location"><span data-hover="Local Area">Local Area</span></a></li>
				    <li className="nav__item"><a className="nav__link" href="#guests"><span data-hover="Guest Book">Guest Book</span></a></li>
					<li className="nav__item"><a className="btn btn__medium" href={bookingLink}><i className="btn-icon-left icon-bookmark"></i>Book Now</a></li>
			    </ul>
	        </div>
		    <div className="navbar__btn">
		        <a className="btn btn__medium" href={bookingLink}><i className="btn-icon-left icon-bookmark"></i>Book Now</a>
		    </div>	
	    </nav>
	  </header>
  )
}