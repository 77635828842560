import React, {useState, useEffect} from "react"
import "../styles/style.scss"
import Header from "./header"
import Footer from "./footer"
import { useStaticQuery, graphql, withPrefix } from 'gatsby'
import { Helmet } from "react-helmet"
// import { Dots } from 'react-preloaders';


export default function Layout({ children }) {
  
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    
    if( /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      document.querySelector('html').classList.add('touch')
        setIsMobile(true);
        
    }
    else {
      document.querySelector('html').classList.add('no-touch')
        setIsMobile(false)
    }
    //IE, Edge
    let isIE = /MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent) || /MSIE 10/i.test(navigator.userAgent) || /Edge\/\d+/.test(navigator.userAgent);
  }, [])
  
  const { contact, site } = useStaticQuery(graphql`
  query {
    contact: allSanityContact {
      edges {
        node {
          email
          phone
          facebook
          instagram
          twitter
        }
      }
    }
    site: allSanitySiteSettings {
      edges {
        node {
          siteName
          siteDescription
          headerLogo {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          bookingLink
        }
      }
    }
  }
  `)

  const contactData = contact.edges[0].node
  const siteData = site.edges[0].node

  return (
    <>
      <Helmet>
        <title>{siteData.siteName}</title>
        <meta name="description" content={siteData.siteDescription}></meta>
      </Helmet>
      {/* <div class="banner">
        <span>Sample Banner</span>
      </div> */}
      <Header {...siteData}/>
      <main className="main">
      {children}
      </main>
      <Footer {...contactData}/>
      {/* <Dots /> */}
    </>
  )
}